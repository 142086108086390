import { Injectable } from "@angular/core";

import { ConstructorType } from "../../../../base/helper/constructor-type";
import { blobToDataURL, readFileAsDataUrlAsync } from "../../../../base/helper/file-reader-helper";
import { AbstractEntity } from "../../../datamodel/abstract-entity";
import { Job } from "../../../datamodel/job";
import { JobTemplate } from "../../../datamodel/job-template";
import { MeasuredPoint } from "../../../datamodel/measured-point";
import { Measurement } from "../../../datamodel/measurement";
import { Part } from "../../../datamodel/part";
import { Photo } from "../../../datamodel/photo";
import { UiHelper } from "../../../helpers/ui-helper";
import { ImageService } from "../../../services/image/image-service";
import { SchemaMetaData } from "./schema-meta-data";
import { AbstractEntityV1 } from "./v1/abstract-entity.v1";
import { ImageV1 } from "./v1/image.v1";
import { JobV1 } from "./v1/job.v1";
import { JobFileV1 } from "./v1/job-file.v1";
import { JobTemplateV1 } from "./v1/job-template.v1";
import { JobTemplateFileV1 } from "./v1/job-template-file.v1";
import { MeasuredPointV1 } from "./v1/measured-point.v1";
import { MeasurementV1 } from "./v1/measurement.v1";
import { MeasurementPointV1 } from "./v1/measurement-point.v1";
import { PartV1 } from "./v1/part.v1";
import { PhotoV1 } from "./v1/photo.v1";
import { PropertyV1 } from "./v1/property.v1";

/**
 * Exporter for templates and jobs.
 */
@Injectable({
    providedIn: "root"
})
export class JobTemplateExporter {
    private readonly jobMimeType: string = "application/vnd.rhopointinstruments.quick-report-job+json; charset=utf-8";
    private readonly jobTemplateMimeType: string = "application/vnd.rhopointinstruments.quick-report-job-template+json; charset=utf-8";

    constructor(
        private imageService: ImageService
    ) {}

    public async exportJob(job: Job): Promise<void> {
        const v1: JobFileV1 = await this.jobToFileStructure(job);

        v1.job!.correlationId = job.correlationId;
        v1.job!.measuredPoints = [];
        for (const point of job.measuredPoints) {
            v1.job!.measuredPoints.push(this.toMeasuredPointV1(point));
        }
        v1.job!.photos = [];
        for (const photo of job.photos) {
            v1.job!.photos.push(await this.toPhotoV1(photo));
        }

        const blob: Blob = this.getBlob(v1, this.jobMimeType);
        await UiHelper.spawnDownload(blob, `job-${job.id}.qrj`, this.jobMimeType);
    }

    public async exportJobTemplate(jobTemplate: JobTemplate): Promise<void> {
        const v1: JobTemplateFileV1 = await this.jobTemplateToFileStructure(jobTemplate);

        const blob: Blob = this.getBlob(v1, this.jobTemplateMimeType);
        await UiHelper.spawnDownload(blob, `job-template-${jobTemplate.id}.qrt`, this.jobTemplateMimeType);
    }

    private getBlob(entity: object, mimeType: string): Blob {
        const json: string = JSON.stringify(entity, undefined, 0);
        return new Blob([json], { type: mimeType });
    }

    private async jobTemplateToFileStructure(jobTemplate: JobTemplate): Promise<JobTemplateFileV1> {
        const v1: JobTemplateFileV1 = new JobTemplateFileV1();
        v1.metaData = new SchemaMetaData();
        v1.metaData.type = "jobTemplate";
        v1.metaData.version = "v1";
        v1.jobTemplate = await this.setJobTemplateProperties(jobTemplate, JobTemplateV1);
        return v1;
    }

    private async setJobTemplateProperties<TClass extends JobTemplateV1>(jobTemplate: JobTemplate, classConstructor: ConstructorType<TClass>): Promise<TClass> {
        const templateV1: TClass = new classConstructor();
        templateV1.id = jobTemplate.id;
        templateV1.version = jobTemplate.version;
        templateV1.correlationId = jobTemplate.correlationId;
        templateV1.properties = jobTemplate.properties.map(PropertyV1.fromEntity);
        templateV1.parts = await Promise.all(jobTemplate.parts.map((part: Part) => this.toPartV1(part)));
        return templateV1;
    }

    private async jobToFileStructure(job: Job): Promise<JobFileV1> {
        const v1: JobFileV1 = new JobFileV1();
        v1.metaData = new SchemaMetaData();
        v1.metaData.type = "job";
        v1.metaData.version = "v1";

        v1.job = await this.setJobTemplateProperties(job, JobV1);
        return v1;
    }

    private applyAbstractEntityProperties(abstractEntity: AbstractEntity, v1: AbstractEntityV1): void {
        v1.id = abstractEntity.id;
        v1.correlationId = abstractEntity.correlationId;
        v1.version = abstractEntity.version;
    }

    private async toPartV1(part: Part): Promise<PartV1> {
        const partV1: PartV1 = new PartV1();
        this.applyAbstractEntityProperties(part, partV1);

        partV1.properties = part.properties.map(PropertyV1.fromEntity);
        if (part.image) {
            partV1.image = new ImageV1();
            partV1.image.imageWithMeasurementPointsAsDataURL = part.image.imageWithMeasurementPointsAsDataURL;
            if (part.image.binaryId) {
                const image: Blob|undefined = await this.imageService.load(part.image.binaryId);
                if (image) {
                    partV1.image.binaryAsDataURL = await blobToDataURL(image);
                }
            }
            partV1.image.measurementPoints = part.image.measurementPoints.map(MeasurementPointV1.fromEntity);
        }
        return partV1;
    }

    private toMeasuredPointV1(measuredPoint: MeasuredPoint): MeasuredPointV1 {
        const v1: MeasuredPointV1 = {
            partId: measuredPoint.partId,
            measurementPointId: measuredPoint.measurementPointId,
            measurements: []
        };

        for (const measurement of measuredPoint.measurements) {
            v1.measurements.push(this.toMeasurementV1(measurement));
        }

        return v1;
    }

    private toMeasurementV1(measurement: Measurement): MeasurementV1 {
        return MeasurementV1.fromEntity(measurement);
    }

    private async toPhotoV1(photo: Photo): Promise<PhotoV1> {
        const partialV1: Partial<PhotoV1> = PhotoV1.partialFromEntity(photo);
        const v1: PhotoV1 = new PhotoV1();
        Object.assign(v1, partialV1);

        if (photo.binaryId) {
            const binary: Blob|undefined = await this.imageService.load(photo.binaryId);
            if (binary) {
                const file: File = new File([binary], binary.type);
                v1.binaryAsDataURL = await readFileAsDataUrlAsync(file);
            }
        }

        return v1;
    }
}
