import { Component } from "@angular/core";
import type { ScanResult } from "@capacitor-community/bluetooth-le/dist/esm/definitions";
import { ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

import { BaseComponent } from "../../../base/components/base/base-component";
import { BluetoothService } from "../../../base/services/ble/bluetooth.service";
import { BluetoothDevice } from "../../../base/services/ble/devices/bluetooth.device";
import { CoatingThicknessGauge } from "../../../base/services/ble/devices/concrete/coating-thickness-gauge";
import { Glossmeter } from "../../../base/services/ble/devices/concrete/glossmeter";
import { DeviceCodes } from "../../../base/services/ble/devices/device-codes";
import { UiHelper } from "../../helpers/ui-helper";
import { BackendService } from "../../services/backend/backend-service";
import { DialogService } from "../../services/dialog/dialog.service";
import { SettingsService } from "../../services/settings/settings-service";

/**
 * Component to scan BLE devices.
 */
@Component({
    selector: "app-scan-devices",
    templateUrl: "./scan-devices.component.html",
    styleUrls: ["./scan-devices.component.scss"]
})
export class ScanDevicesComponent extends BaseComponent {
    constructor(
        private readonly bluetoothService: BluetoothService,
        private readonly settingsService: SettingsService,
        private readonly backendService: BackendService,
        private readonly dialogService: DialogService,
        private readonly translateService: TranslateService,
        private readonly modalController: ModalController
    ) {
        super();
    }

    private readonly knownServices: Array<string> = [
        Glossmeter.deviceInfoService,
        Glossmeter.measurementService,
        CoatingThicknessGauge.measurementService,
        "fallback"
    ];

    protected readonly uiHelper: typeof UiHelper = UiHelper;

    private refreshHandle: any|undefined;

    protected scanning: boolean = true;

    private scannedDevices: Array<ScanResult> = [];
    protected filteredDevices: Array<ScanResult> = [];

    protected override componentInit(): void {
        const self: ScanDevicesComponent = this;
        this.bluetoothService.startScan(this.deviceUpdate.bind(self)).then();
        this.refreshHandle = setInterval(() => {
            if (!self.bluetoothService.scanning) {
                self.stopRefreshTimer();
                return;
            }
            self.refreshList();
        },
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        3000);
    }

    private stopRefreshTimer(): void {
        if (this.refreshHandle) {
            clearInterval(this.refreshHandle);
            this.refreshHandle = undefined;
        }
    }

    protected override componentDestroy(): void {
        this.bluetoothService.stopScan().then();
    }

    private deviceUpdate(_scanResult: ScanResult): void {
        this.refreshList();
    }

    private refreshList(): void {
        this.scannedDevices = this.bluetoothService.scannedDevices;
        const filtered: Array<ScanResult> = [];
        let fallback: boolean = false;
        for (const scannedDevice of this.scannedDevices) {
            const availableUuids: Array<string> = scannedDevice.device.uuids?.map((uuid: string) => uuid.toLowerCase()) ?? [];
            availableUuids.push(...scannedDevice?.uuids?.map((uuid: string) => uuid.toLowerCase()) ?? []);

            for (const serviceUuid of availableUuids) {
                fallback = fallback || serviceUuid == "fallback";
                if (this.knownServices.includes(serviceUuid)) {
                    filtered.push(scannedDevice);
                    break;
                }
            }
        }
        this.filteredDevices = filtered;

        this.scanning = !fallback;
    }

    protected close(): void {
        this.stopRefreshTimer();
        this.bluetoothService.stopScan().then();
        this.modalController.dismiss().then();
    }

    public async connectToDevice(scanResult: ScanResult): Promise<void> {
        this.stopRefreshTimer();
        await this.bluetoothService.stopScan();

        this.modalController.dismiss().then();

        if (scanResult.uuids?.includes("fallback")) {
            let device: BluetoothDevice|undefined;
            switch (scanResult.device.deviceId) {
                case DeviceCodes.glossmeter: {
                    device = new Glossmeter(this.backendService, this.settingsService, this.dialogService, this.translateService);
                    break;
                }
                case DeviceCodes.thicknessGauge: {
                    device = new CoatingThicknessGauge(this.backendService, this.settingsService, this.dialogService);
                    break;
                }
            }
            if (device) {
                await this.bluetoothService.tryConnect(device);
            }
        } else {
            this.bluetoothService.connectThroughRawDevice(scanResult.device, scanResult).then();
        }
    }
}
