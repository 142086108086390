<ion-header>
    <ion-toolbar>
        <ion-title size="large">{{'CsvExport.exportSettingsTitle'| translate}}</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="close()"><fa-icon [icon]="appIcons.dialogClose"></fa-icon></ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

<ion-content [fullscreen]="true" class="componentRoot">
    <ion-item lines="none">
        <ion-input slot="start" labelPlacement="start" [(ngModel)]="csvSettings.filename" class="filenameInput" label="{{'CsvExport.filename'|translate}}"></ion-input>
    </ion-item>
    <ion-item lines="none">
        <ion-select [(ngModel)]="csvSettings.delimiter" interface="popover" label="{{'CsvExport.delimiter' | translate}}">
            <ion-select-option value=";">{{'CsvExport.delimiterSemicolon' | translate}}</ion-select-option>
            <ion-select-option value=",">{{'CsvExport.delimiterComma' | translate}}</ion-select-option>
            <ion-select-option value="\t">{{'CsvExport.delimiterTab' | translate}}</ion-select-option>
        </ion-select>
    </ion-item>
    <ion-item lines="none">
        <ion-select [(ngModel)]="csvSettings.timeFormat" interface="popover" label="{{'CsvExport.timeFormat'|translate}}">
            <ion-select-option value="iso-utc">{{'CsvExport.timezoneIsoUtc'|translate}}</ion-select-option>
            <ion-select-option value="iso-local">{{'CsvExport.timezoneIsoLocal'|translate}} ({{localTimezoneOffset}})</ion-select-option>
        </ion-select>
    </ion-item>
    <ion-item lines="none">
        <ion-select [(ngModel)]="csvSettings.decimalSeparator" interface="popover" label="{{'CsvExport.decimalSeparator'|translate}}">
            <ion-select-option value=".">{{'CsvExport.decimalSeparatorDot'|translate}}</ion-select-option>
            <ion-select-option value=",">{{'CsvExport.decimalSeparatorComma'|translate}}</ion-select-option>
        </ion-select>
    </ion-item>
    <ion-item lines="none">
        <ion-select [(ngModel)]="csvSettings.encoding" interface="popover" label="{{'CsvExport.encoding'|translate}}">
            <ion-select-option value="UTF-8">{{'CsvExport.utf8'|translate}}</ion-select-option>
            <ion-select-option value="UTF-8-BOM">{{'CsvExport.utf8Bom'|translate}}</ion-select-option>
            <ion-select-option value="Windows-1252">{{'CsvExport.windows1252'|translate}}</ion-select-option>
        </ion-select>
    </ion-item>

    <ion-item lines="none">
        <ion-checkbox slot="start" labelPlacement="end" [(ngModel)]="csvSettings.units">{{'CsvExport.addUnits'|translate}}</ion-checkbox>
    </ion-item>

    <ion-item *ngIf="gonioCurveActive" lines="none">
        <ion-checkbox slot="start" labelPlacement="end" [(ngModel)]="csvSettings.gonioCurve">{{'CsvExport.addCurveData'|translate}}</ion-checkbox>
    </ion-item>

    <ion-item-divider *ngIf="allMeasurements.length > 0">
        <ion-label>{{'CsvExport.selectMeasurements'|translate}}</ion-label>
    </ion-item-divider>

    <ion-item *ngFor="let measurement of allMeasurements" lines="none">
        <ion-checkbox slot="start" labelPlacement="end" [checked]="isChecked(measurement)" (ionChange)="checkMeasurement(measurement, $event)">
            <ion-label *ngIf="measurement.localId > 0 || measurement.name"><span *ngIf="measurement.localId > 0">{{uiHelper.numberToLetters(measurement.localId)}}</span><span *ngIf="measurement.localId > 0 && measurement.name"> - </span>{{measurement.name ?? ''}}</ion-label>
        </ion-checkbox>
    </ion-item>
</ion-content>

<ion-footer>
    <ion-toolbar>
        <ion-buttons slot="end">
            <ion-button (click)="save()" color="primary"><fa-icon [icon]="appIcons.csvIcon"></fa-icon>&nbsp;{{'CsvExport.exportCsv'|translate}}</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-footer>
