import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as Iconv from "iconv-lite";
import { DateTime } from "luxon";

import { CryptoHelper } from "../../../base/helper/crypto-helper";
import { Chart } from "../../datamodel/chart";
import { Measurement } from "../../datamodel/measurement";
import { SpecialColumns } from "../../datamodel/special-columns";
import { CsvSettings } from "../../entities/csv/csv-settings";
import { UiHelper } from "../../helpers/ui-helper";

/**
 * Service to export data to CSV.
 */
@Injectable({
    providedIn: "root"
})
export class CsvExporterService {
    constructor(
        private readonly translateService: TranslateService
    ) {
    }

    // eslint-disable-next-line complexity
    public async export(csvSettings: CsvSettings): Promise<void> {
        const csv: Array<string> = [];
        csvSettings.columns = csvSettings.columns.filter((column: string) => ![`${SpecialColumns.charts}`].includes(column));

        const columns: Array<string> = [];
        for (const column of csvSettings.columns) {
            const unit: string|undefined = csvSettings.units ? UiHelper.getUnitForColumn(column, csvSettings.selectedMeasurements, true, true) : undefined;
            const name: string = this.translateService.instant(column) as string ?? column;
            columns.push(this.sanitizeCell(unit ? `${name}${unit}` : name));
        }
        if (csvSettings.gonioCurve) {
            columns.push(...this.buildGraphHeaders(csvSettings.selectedMeasurements));
        }
        csv.push(columns.join(csvSettings.delimiter));


        for (const measurement of csvSettings.selectedMeasurements) {
            const row: Array<string> = [];
            for (const column of csvSettings.columns) {
                let value: string = UiHelper.getValueForColumn(column, measurement);
                if (column === SpecialColumns.timestamp && !!value) {
                    const dt: DateTime = DateTime.fromISO(value);
                    value = (csvSettings.timeFormat == "iso-local" ? dt.toLocal().toISO() : dt.toUTC().toISO()) ?? value;
                }
                const cellValue: string = value ? this.translateService.instant(value) as string : "";
                row.push(this.sanitizeCell(cellValue));
            }

            if (csvSettings.gonioCurve) {
                row.push(...this.buildGraphCells(measurement, row.length, columns));
            }

            if (csvSettings.decimalSeparator == ",") {
                for (let cellIndex: number = 0; cellIndex < row.length; cellIndex++) {
                    if (!isNaN(parseFloat(row[cellIndex]))) {
                        row[cellIndex] = row[cellIndex].replace(".", ",");
                    }
                }
            }

            csv.push(row.join(csvSettings.delimiter));
        }

        const csvString: string = csv.join("\r\n").replace(/°/g, "");
        let base64Data: string;
        if (csvSettings.encoding.startsWith("UTF-8")) {
            const utf8Array: Uint8Array = new TextEncoder().encode(csvSettings.encoding.endsWith("BOM") ? `\uFEFF${csvString}` : csvString);
            base64Data = btoa(String.fromCharCode(...utf8Array));
        } else {
            const windows1252Buffer: Buffer = Iconv.encode(csvString, "windows-1252");
            base64Data = windows1252Buffer.toString("base64");
        }

        let filename: string = UiHelper.sanitizeFilename(csvSettings.filename, false).trim();
        if (!filename) {
            filename = `${DateTime.local().toFormat("yyyy-LL-dd")}-${CryptoHelper.getUUID()}.csv`;
        }
        if (!filename.endsWith(".csv")) {
            filename += ".csv";
        }

        await UiHelper.spawnDownload(base64Data, filename, csvSettings.encoding.startsWith("UTF-8") ? "text/csv; charset=utf-8" : "text/csv; charset=windows-1252");
    }

    private sanitizeCell(cellValue: string|undefined): string {
        if (!cellValue) {
            return "";
        }
        if (cellValue.includes(",") || cellValue.includes(";") || cellValue.includes("\t") || cellValue.includes("\r") ||  cellValue.includes("\n") || cellValue.includes("\"")) {
            return `"${cellValue.replace("\"", "\"\"")}"`;
        }
        return cellValue;
    }

    private buildGraphHeaders(measurements: Array<Measurement>): Array<string> {
        const headers: Set<string> = new Set<string>();
        for (const measurement of measurements) {
            for (let i: number = 0; i < measurement.charts.length; i++) {
                const chart: Chart = measurement.charts[i];
                for (const data of chart.data) {
                    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                    headers.add(`G${UiHelper.numberToLetters(i+1)}-${data.x.toFixed(2)}`);
                }
            }
        }
        return Array.from(headers).sort((a: string, b: string) => parseFloat(a.split("-")[1]) - parseFloat(b.split("-")[1]));
    }

    private buildGraphCells(measurement: Measurement, nextIndex: number, columns: Array<string>): Array<string> {
        const values: Array<string> = [];
        for (let i: number = 0; i < measurement.charts.length; i++) {
            const chart: Chart = measurement.charts[i];
            for (const data of chart.data) {
                const column: string = `G${UiHelper.numberToLetters(i+1)}-${data.x.toFixed(2)}`;
                if (columns.includes(column)) {
                    const index: number = columns.indexOf(column);
                    values[index] = `${data.y}`;
                }
            }
        }
        for (let i: number = 0; i < columns.length; i++) {
            if (!values[i]) {
                values[i] = "";
            }
        }

        return values.splice(nextIndex);
    }
}
